/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgba(100, 116, 139, 0.5) rgba(100, 116, 139, 0.16);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: rgba(100, 116, 139, 0.16);
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(100, 116, 139, 0.5);
  border-radius: 0.25rem;
  border: 0.5rem solid rgba(100, 116, 139, 0.16);
}

/* Ant design */
.ant-popover:not(.ant-popconfirm) .ant-popover-inner-content {
  padding: 0;
}
